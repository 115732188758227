.root {
  display: flex;
  width: 424px;
}

.button {
  background-color: #1482fc;
  height: 40px;
  width: 40px;
}

.label {
  width: unset;
}

.icon {
  position: relative;
  right: 24px;
  top: 12px;
}
