.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.errorCode {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
}

.CreateEditCustomerList_row__robJv {
  padding-bottom: 20px;
}
