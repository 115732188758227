.head {
  background-color: #fff;
  position: sticky;
  top: 0;
}

.link {
  color: #1482fc;
  cursor: pointer;
  text-decoration: none;
}

.pagination {
  margin: 0 0 32px auto;
}

.paper {
  max-height: 100%;
  overflow-y: auto;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
}

.toolbar {
  display: flex;
  margin-bottom: 16px;
}
