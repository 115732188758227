.root {
  align-items: center;
  display: flex;
  flex-wrap: nowrap;
  font-family: Graphik;
  font-size: 10px;
  padding: 16px 0 24px;
}

.darkBlue {
  color: #011f42;
}

.lightBlue {
  color: #1482fc;
  text-decoration: none;
}
