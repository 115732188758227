.error {
  color: #f44336;
  font-size: 0.75rem;
  height: 19px;
  margin-top: 4px;
}

.input-wrapper input {
  width: 100% !important;
}

.input-wrapper input:focus {
  outline: none !important;
  border: 1px solid #1482fc;
}

.input-wrapper input::placeholder {
  color: rgb(82 95 127 / 35.4%);
}
