.root {
  font-family: Graphik;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  width: 100%;
  padding: 20px;
}

button.default {
  color: #525f7f;
  stroke: #525f7f;
}

button.default:hover {
  color: #394157;
  stroke: #394157;
}

button.activated {
  background: #eef6ff;
  border: 2px solid #1482fc;
  color: #1482fc;
  stroke: #1482fc;
}

button.activated:hover {
  background: #e4eefa;
  border: 2px solid #0561c8;
  color: #0561c8;
  stroke: #0561c8;
}

button.open {
  border-radius: 5px 5px 0 0;
}

input {
  background-color: #fff;
  border-radius: 4px;
  border: solid 1px #e4ebef;
  color: #4f5f82;
  font-size: 12px;
  height: 40px;
  line-height: 1.33;
  padding: 12px 4px 12px 16px;
  width: 100%;
}

input::-ms-clear {
  display: none;
}

.header {
  color: #011f42;
  font-size: 12px;
  font-family: Graphik;
  font-weight: 800;
  line-height: 30px;
  margin-top: 12px;
  text-transform: uppercase;
  letter-spacing: 0.8px;
}

.popover {
  border: 1px solid #e4ebef;
  border-radius: 0 5px 5px;
}
