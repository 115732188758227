.root {
  margin-bottom: 24px;
}

.content {
  padding: 24px;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  color: #011f42;
  font-size: 16px;
  margin-bottom: 8px;
}

.loading {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 80px;
  width: 100%;
}

.paper {
  max-height: 100%;
  width: 100%;
  padding: 24px;
}

.row {
  display: flex;
  flex-direction: row;
}
