.buttons {
  display: flex;
  justify-content: flex-end;
  margin: 40px 0 24px;
}

.circularProgress {
  display: flex;
  justify-content: center;
  position: relative;
  top: 35%;
}

.column {
  display: flex;
  flex-direction: column;
}

.reactFlow {
  height: 562px !important;
  width: 100% !important;
}

.row {
  display: flex;
  flex-direction: row;
}

.toolbar {
  align-items: flex-end;
}
