.content-wrapper {
  align-items: center;
  display: flex;
  padding: 20px 40px;
}

.buttons-wrapper {
  display: flex;
  justify-content: flex-end;
  min-width: 96px;
  margin-left: 16px;
}
