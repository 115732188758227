.card-container {
  margin: 0;
  display: flex;
  flex-direction: row;
  gap: 18px;
  padding: 24px 32px;
  align-items: center;
}

.title {
  font-family: Graphik;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  color: #011f42;
}

.icon-container {
  width: 40px;
  height: 40px;
  background-color: #cac4ff;
  flex-shrink: 0;
  border-radius: 6px;
  display: flex;
  justify-content: center;
}

.icon {
  width: 24px;
}

.link {
  text-decoration: none;
}

.card-disabled {
  filter: grayscale(100%) brightness(0.91);
}
