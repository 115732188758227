.buttons {
  display: flex;
  justify-content: center;
  margin-bottom: 8px;
}

.title {
  font-family: Graphik !important;
  padding: 10px;
}
