/* IMPORTS */
@font-face {
  font-family: Graphik;
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/Graphik/Graphik-Regular.otf');
}

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  margin: 0;
}

/* APPLICATION CONTAINER */
:global(#root) {
  background-color: #f7f8fa;
  height: 100%;
  min-width: 1024px;
}

b {
  font-weight: bold !important;
}

/* FONT FAMILIES */
.Graphik {
  font-family: Graphik, OpenSans, sans-serif;
}

.container {
  margin: 0 60px;
}

@media (max-width: 768px) {
  .container {
    min-width: 768px;
  }
}
@media (min-width: 1500px) {
  .container {
    max-width: 100%;
  }
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  box-shadow: 0 0 0 30px white inset !important;
}
