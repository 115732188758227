.errorCode {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
}

.notFound {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgb(124 143 151 / 20%);
  padding: 24px 0 8px 24px;
}
