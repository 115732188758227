.header-container {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.header-title {
  margin: 0 0 0 12px;
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -0.02em;
}

.header-close-icon {
  color: #0b1641;
  margin-left: auto;
  width: 28px !important;
  height: 28px !important;
}

.header-separator {
  height: 1px;
  width: 100%;
  background-color: #e8e7eb;
  margin-bottom: 16px;
}

.table-wrapper {
  height: calc(100vh - 140px);
  overflow: auto;
}

.table-container {
  width: 100%;
  text-align: left;
  table-layout: fixed;
  border-collapse: collapse;
}

.table-head {
  background-color: white;
  position: sticky;
  top: 0;
}

.table-column-id {
  width: 30%;
  font-size: 12px;
  font-weight: 500;
  color: #8d8b99;
}

.table-column-error {
  width: 70%;
  font-size: 12px;
  font-weight: 500;
  color: #8d8b99;
}

.table-row {
  font-size: 14px;
  font-weight: 500;
  color: #1a1a1a;
}

.table-row-error-wrapper {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.paper-container {
  position: absolute;
  max-height: 100vh;
  height: 100vh;
  right: 0;
  margin: 0;
  width: 500px;
}
