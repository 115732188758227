.tableRoot {
  max-height: 100%;
  overflow-y: auto;
}

.loading {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 80px;
  width: 100%;
}

.tableHead {
  position: relative;
}
