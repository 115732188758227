.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  width: 100%;
}

.bottom {
  background-color: #f7f8fa;
  flex-basis: 50%;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.pill {
  margin-left: auto;
  overflow: hidden;
}

.show {
  visibility: visible;
}

.subHeader {
  color: #00214a;
  font-family: Graphik;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 4px;
}

.subtext {
  color: #3d628b;
  font-family: Graphik;
  font-size: 12px;
  line-height: 1.5;
  margin: 0;
}

.syringe {
  align-self: flex-end;
  margin-right: auto;
}

.title {
  color: #011f42;
  font-family: Graphik;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.71;
  margin: 0;
}

.top {
  background-color: #011f42;
  display: flex;
  flex-basis: 50%;
}

/* https://loading.io/css/ */

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
