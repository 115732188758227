.root {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
}

.title {
  margin-bottom: 20px;
}

.form-wrapper {
  margin-bottom: 20px;
}

.form {
  display: flex;
  justify-content: space-between;
  margin-bottom: 0;
}

.fields-wrapper {
  width: 48%;
}

.label {
  font-size: 12px;
  font-weight: 700;
  width: 55%;
}

.label-name {
  margin-bottom: 15px;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}

.buttons > button {
  margin-right: 8px;
  min-width: 152px;
  padding: 0;
}

.input {
  width: 55%;
  display: block;
  margin: 15px 0;
  border-radius: 6px;
}

.date-picker {
  width: 55%;
  display: block;
  margin: 15px 0 0;
  border-radius: 6px;
}

.popper {
  z-index: 2;
}

.select {
  padding: 10px;
  width: 65%;
}

.row {
  margin-bottom: 20px;
}
