.root {
  align-items: center;
  display: flex;
  justify-content: center;
}

.input {
  border-radius: 0;
  padding-left: 0;
  text-align: center;
  width: 55px;
}

.input:focus {
  border: 1px solid #1482fc;
  outline: none;
}

.text {
  composes: Graphik from 'styles/Theme.css';
  color: #525f7f;
  font-size: 12px;
  padding: 0 20px;
}
