.hide {
  display: none !important;
}

.menuButton {
  background-color: #fff !important;
  border: 1px solid #e4ebef !important;
  height: 20px;
  left: -10px;
  padding: 0 0 0 4px !important;
  position: relative;
  width: 20px;
  z-index: 2;
}
