.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.infoTable {
  display: flex;
  flex-direction: row;
  padding-top: 24px;
  padding-left: 24px;
  padding-right: 24px;
}

.link {
  text-decoration: none;
}

.value-text {
  max-width: 90%;
  font-size: 12px;
  color: #4f5f82;
}

.paper {
  display: flex;
  flex: 0 0 136px;
  margin-bottom: 24px;
  margin-right: 24px;
  border-radius: 8px;
  flex-direction: column;
}

.status {
  width: auto;
}

.statusText {
  display: inline-block;
  border-radius: 10px;
  color: #fff;
  font-family: Graphik;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  padding: 0 8px;
  width: fit-content;
  text-align: center;
}

.subtitle {
  width: 100%;
  flex-direction: column;
  padding-left: 24px;
  font-family: Graphik;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  color: #525f7f;
}

.title {
  padding-left: 24px;
  padding-top: 24px;
  width: 100%;
  flex-direction: column;
  color: #011f42;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.sub-header {
  margin-bottom: 16px;
}
