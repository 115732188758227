.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  display: flex;
  flex-wrap: wrap;
}

.paperContent {
  padding: 24px 24px 0;
  width: 100%;
}

.paperMargin {
  margin-bottom: 24px;
}
