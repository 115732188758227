.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  display: flex;
  flex-wrap: wrap;
}

.header {
  color: #011f42;
  font-size: 16px;
  margin-bottom: 24px;
}

.paperContent {
  padding: 24px 24px 0;
  width: 100%;
}

.paperMargin {
  margin-bottom: 24px;
}
