.root {
  padding-top: 40px;
}

.column {
  display: flex;
  flex-direction: column;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.disabled {
  opacity: 0.3;
  pointer-events: none;
}

.isOON {
  background: #5f55bc !important;
}

.link {
  flex: 0 0 240px;
  height: 184px;
  margin-bottom: 24px;
  margin-right: 24px;
  text-align: center;
  text-decoration: none;
}

.name {
  color: #4e5e84;
  font-size: 24px;
  font-weight: 600;
}

.network {
  align-self: flex-end;
  background: #00bcd4;
  border-radius: 20px 0 0 20px;
  bottom: 20px;
  color: #fff;
  display: flex;
  font-size: 10px;
  height: 16px;
  justify-content: center;
  line-height: 16px;
  position: absolute;
  width: 96px;
}

.paper {
  color: #4e5e84;
  align-items: center;
  height: 184px;
  justify-content: center;
  position: relative;
}

.row {
  display: flex;
  flex-direction: row;
}

.toolbar {
  margin-bottom: 16px;
}
