.root {
  color: #00214a;
  font-family: Graphik;
  font-size: 12px;
  margin: 14px 24px;
}

.blue {
  color: #1482fc;
  cursor: pointer;
}

.button {
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0;
}

.buttonContainer {
  float: right;
  margin: 0 24px 24px;
}

.circle {
  background-color: #525f7f;
  border-radius: 50%;
  height: 16px;
  width: 16px;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.csvFormat {
  font-size: 11px;
}

.dropContainer {
  align-items: center;
  border: 1px dashed rgb(82 95 127 / 50%);
  border-radius: 6px;
  color: #525f7f;
  display: flex;
  font-size: 14px;
  height: 152px;
  justify-content: center;
  margin: 0;
  width: 100%;
}

.dropMessage {
  text-align: center;
}

.fileDisplayContainer {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  margin-top: 45px;
  position: relative;
  width: 100%;
}

.fileErrorMessage {
  color: #cb6671;
  margin-top: 8px;
}

.fileInput {
  display: none;
}

.fileRemove {
  cursor: pointer;
  position: absolute;
  right: 0;
}

.progressBar {
  background-color: #1482fc;
  border-radius: 4px;
  display: none;
  width: 100%;
}

.progressContainer {
  border-radius: 4px;
  display: none;
  height: 6px;
}

.text {
  margin-top: 28px;
}
