.root {
  margin-bottom: 24px;
}

.arrowContainer {
  align-items: center;
  align-self: end;
  border: 1px solid #e4ebef;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.column {
  display: flex;
  flex-direction: column;
}

.content {
  padding: 24px 24px 0;
  width: 100%;
}

.header {
  color: #011f42;
  font-size: 16px;
  margin-bottom: 24px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.source {
  color: #3d628b;
  font-size: 12px;
  justify-content: space-between;
}
