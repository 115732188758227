.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.sub-header {
  margin-bottom: 16px;
}

.align-bottom {
  margin-top: auto;
}
