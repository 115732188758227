.root {
  display: flex;
  flex-direction: row;
  margin-bottom: 14px;
}

.text {
  color: #5e6977;
  font-family: Graphik;
  font-size: 18px;
  margin-left: 12px;
}
