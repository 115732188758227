.root {
  display: flex;
  justify-content: space-between;
}

.closeButton {
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  height: 24px;
  outline: none;
  padding: 0;
  position: absolute;
  right: 8px;
  top: 8px;
  width: 24px;
}

.title {
  composes: Graphik from 'styles/Theme.css';
  composes: HeaderH5 from 'styles/base/typography.css';
  color: #002045;
  font-weight: bold;
  line-height: 28px;
}
