.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.buttons {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
}
