.root {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgb(124 143 151 / 11%);
  margin-bottom: 24px;
  padding: 24px;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
}

.header {
  color: #011f42;
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
}

.row {
  display: flex;
  flex-flow: row wrap;
}
