.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 24px;
}

.errorCode {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
}

.pagination {
  margin: 16px 0 32px auto;
}

.search {
  margin-bottom: 8px;
}
