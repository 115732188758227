.root {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgb(124 143 151 / 20%);
  display: flex;
  flex-direction: column;
  padding: 24px 24px 48px;
  margin-bottom: 24px;
}

.field {
  color: #4f5f82;
  display: flex;
  flex-direction: row;
  font-size: 12px;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
}

.cell {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.icon {
  display: flex;
}

.title {
  color: #011f42;
  font-size: 16px;
}
