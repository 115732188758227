.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  display: flex;
  flex-wrap: wrap;
}

.header {
  color: #011f42;
  font-size: 16px;
  margin-bottom: 24px;
}

.key {
  color: #00214a;
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
}

.paperContent {
  padding: 24px 24px 0;
  width: 100%;
}

.paperMargin {
  margin-bottom: 24px;
}

.divider {
  width: 100%;
  border: none;
  border-top: 1px solid #e4ebef;
}
