.root {
  width: 100%;
}

.buttonContainer {
  align-self: flex-end;
  display: flex;
  margin-bottom: 40px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
