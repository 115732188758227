.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  color: #011f42;
  font-size: 16px;
  margin-bottom: 24px;
}

.paperContent {
  padding: 24px 24px 0;
  width: 100%;
}

.paperMargin {
  margin-bottom: 24px;
}

.row {
  display: flex;
  flex-direction: row;
}

.status {
  width: auto;
}

.value {
  font-size: 12px;
  color: #4f5f82;
  min-height: 16px;
}

.statusText {
  border-radius: 10px;
  color: #fff;
  display: inline-block;
  font-family: Graphik;
  font-weight: normal;
  font-size: 10px;
  line-height: 16px;
  padding: 0 8px;
}
