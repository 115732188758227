.buttons-section {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 8px;
  gap: 12px;
}

.form {
  padding-top: 40px;
}
