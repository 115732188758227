.loading-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 64px;
}

.flatfile-container {
  width: 100%;
  height: 100%;
  border-radius: 24px;
  color: white;
  box-shadow: 0 0 16px 2px rgb(1 126 250 / 10%),
    0 2px 4px -2px rgb(1 126 250 / 10%);
}

.flatfile-container > iframe {
  border: 0;
  width: 100%;
  height: 100%;
  border-radius: 24px;
}

.progress {
  font-size: 16px;
}

.error-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #011f42;
  font-size: 24px;
  height: 100%;
}

.error-icon {
  height: 3em !important;
  width: 3em !important;
  margin-bottom: 16px;
}

.base-container {
  position: relative;
}

.close-btn {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 99;
  background: none;
  border: none;
  cursor: pointer;
  padding: 24px;
}
