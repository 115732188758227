.root {
  margin-bottom: 24px;
}

.content {
  padding: 24px 24px 0;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Graphik;
}

.header {
  color: #011f42;
  font-size: 16px;
  margin-bottom: 8px;
}

.row {
  display: flex;
  flex-direction: row;
}
