.arrowContainer {
  align-items: center;
  border: 1px solid #e4ebef;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
}

.borderTop {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: 4px;
  width: 100%;
}

.borderTop.success {
  background-color: #00bc96;
}

.borderTop.failure {
  background-color: #cb6671;
}

.borderTop.warning {
  background-color: #f9bc60;
}

.header {
  color: #011f42;
  font-size: 16px;
  margin: 24px 0 8px;
}

.icon {
  position: relative;
  top: 4px;
}

.paperContent {
  padding: 24px 24px 0;
  width: 100%;
}

.paperMargin {
  margin-bottom: 24px;
}

.reasons {
  padding: 0 24px 24px;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
