.root {
  background-color: #f7f8fa;
  display: flex;
  font-family: Graphik;
  height: 100%;
}

.body {
  height: 100%;
  overflow-x: hidden;
  padding: 56px 32px 32px;
  width: 100%;
}

.historyView {
  background-color: rgb(179 214 254 / 16%);
}
