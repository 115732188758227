.root {
  display: flex;
  flex-direction: column;
}

.column {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  margin-right: 120px;
}

.content {
  display: flex;
  flex-wrap: wrap;
}

.row {
  display: flex;
  flex-direction: row;
}

.toolbar {
  justify-content: space-between;
  margin-bottom: 48px;
  margin-top: 32px;
}

.toolbarTitle {
  color: #0b1641;
  font-size: 20px;
  font-weight: bolder;
  margin-bottom: 8px;
}
