.row {
  display: flex;
  flex-direction: row;
  padding-bottom: 20px;
}

.pagination {
  float: right;
  margin: 16px 0 0 auto;
}
