.calendar {
  border: 1px solid #e4ebef;
}

.error {
  color: #f44336;
  font-size: 0.75rem;
  letter-spacing: 0.0333em;
  min-height: 19px;
  margin-top: 4px;
}

input {
  background-color: #fff;
  border-radius: 4px;
  border: solid 1px #e4ebef;
  color: #4f5f82;
  font-size: 12px;
  height: 40px;
  line-height: 1.33;
  padding: 12px 4px 12px 16px;
  width: 100%;
}

input::-ms-clear {
  display: none;
}

.input:disabled {
  color: rgb(82 95 127 / 35%);
}

.input:focus {
  outline: none !important;
  border: 1px solid #1482fc;
}

.input::placeholder {
  color: rgb(82 95 127 / 35.4%);
}

/* Change calendar header color */
:global .react-datepicker__header {
  background-color: #f7f8fa !important;
  border-bottom-color: #e4ebef !important;
}

/* Change calendar selected day color */
:global .react-datepicker__day--selected {
  background-color: #1482fc !important;
}

/* Change calendar selected day color */
:global .react-datepicker__day--keyboard-selected {
  background-color: #1482fc !important;
}

/* Remove carrot extending from calendar */
:global
  .react-datepicker-popper[data-placement^='bottom']
  .react-datepicker__triangle {
  display: none;
}

:global .react-datepicker__triangle {
  left: 40px !important;
}

/* Make sure that the date picker is always on top */
:global .react-datepicker-popper {
  z-index: 9999 !important;
}
