.overview-forms-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.overview {
  width: 64%;
}

.distributors {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgb(124 143 151 / 11.3%);
  display: flex;
  flex-direction: column;
  height: fit-content;
  margin-bottom: 24px;
  padding: 24px;
  width: 34%;
}

.distributors input {
  width: 100%;
}

.distributorsContent {
  display: flex;
  flex-direction: column;
}

.field {
  padding: 0 16px 8px 0;
}

.fields {
  display: flex;
  flex-direction: row;
  padding: 10px 0 10px 38px;
}

.header {
  color: #011f42;
  font-size: 16px;
  margin-bottom: 24px;
}

.key {
  color: #00214a;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
}

@media screen and (max-width: 1024px) {
  .overview,
  .distributors {
    width: 100%;
  }

  .root {
    flex-direction: column;
  }
}
