.root {
  padding: 0 !important;
  max-width: 560px;
  margin-left: -60px;
}

.icon,
.label {
  cursor: pointer;
}

.icon {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35px;
  height: 35px;
  position: relative;
  right: 1.5px;
  background-color: #fff;
  border: 2px solid;
  border-color: rgb(212 212 212);
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;
  border-radius: 50%;
}

.icon path {
  transition: fill 0.3s ease-in-out;
}

.icon path,
.icon g {
  fill: #5e6977;
}

.icon-active path,
.icon-active g {
  fill: #1482fc;
}

.icon-completed path,
.icon-completed g {
  fill: white;
}

.icon-active {
  border-color: #1482fc;
}

.icon-completed {
  background-color: #1482fc;
  border-color: #1482fc;
}

.icon-error {
  background-color: #fff;
  border-color: #dc2626;
  color: #dc2626;
}

.label {
  text-transform: uppercase;
  color: #5e6977;
}

.label-active {
  color: #1482fc;
}

.label-completed {
  color: #000;
}

.label-error {
  color: #dc2626 !important;
  font-weight: bolder !important;
}
