.root {
  display: flex;
  flex-direction: column;
  margin: 40px 0 0 34px;
  min-width: 100px;
  overflow-y: auto;
  overflow-x: hidden;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.column {
  display: flex;
  flex-direction: column;
  position: relative;
}

.dateText {
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  outline: none;
  padding-top: 8px;
}

.dateText p {
  margin: 0;
}

.day {
  color: #011f42;
  font-family: Graphik;
  font-size: 20px;
  margin-bottom: 4px;
}

.dot {
  background-color: rgb(82 95 127 / 50%);
  border-radius: 50%;
  height: 8px;
  left: 6px;
  margin: 6px 0;
  position: relative;
  width: 8px;
}

.header {
  color: #011f42;
  font-family: Graphik;
  font-size: 16px;
  margin: -2px auto;
}

.headerRow {
  margin: 16px 0 14px 4px;
}

.hidden {
  display: none;
}

.longLine {
  border-left: 1px solid rgb(82 95 127 / 50%);
  height: 80px;
  margin-left: 9.5px;
}

.mediumLine {
  border-left: 1px solid rgb(82 95 127 / 50%);
  height: 24px;
  margin-left: 9.5px;
}

.middleDot {
  background-color: transparent;
  border: 1px solid #1482fc;
  border-radius: 50%;
  height: 14px;
  left: 3px;
  position: absolute;
  top: 11px;
  width: 14px;
}

.month {
  color: #525f7f;
  font-family: Graphik;
  font-size: 8px;
}

.row {
  display: flex;
  flex-direction: row;
}

.selectedDate {
  padding-top: 2px;
}

.selectedDate .day {
  color: #1482fc;
  font-size: 28px;
}

.selectedDate .month {
  font-size: 12px;
}

.selectedDot {
  background-color: #1482fc;
}

.shortLine {
  border-left: 1px solid rgb(82 95 127 / 50%);
  height: 8px;
  margin-left: 9.5px;
}

.title {
  color: #00214a;
  font-family: Graphik;
  font-size: 12px;
  font-weight: bold;
  height: 14px;
  line-height: 1.5;
  margin-top: 16px;
  margin-bottom: 8px;
}
