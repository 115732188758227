.root {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgb(124 143 151 / 20%);
  display: flex;
  flex-direction: column;
  padding: 24px 24px 48px;
  margin-bottom: 24px;
}

.field {
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 216px;
}

.key {
  color: #00214a;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 6px;
}

.title {
  color: #011f42;
  font-size: 16px;
}
