.head {
  background-color: #fff;
  position: sticky;
  top: 0;
}

.row {
  display: flex;
  flex-direction: row;
}

.icon {
  position: relative;
  top: 4px;
}

.icon:not(:last-of-type) {
  margin-right: 4px;
}

.link {
  color: #1482fc;
  text-decoration: none;
}
