.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  font-family: Graphik;
}

.ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.key {
  color: #00214a;
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 8px;
}

.link {
  color: #1482fc;
  text-decoration: none;
}

.propertyGroup {
  margin-bottom: 24px;
}

.row {
  display: flex;
  flex-direction: row;
  width: 80%;
}

.titleIcon {
  margin-left: 6px;
}

.value {
  color: #4f5f82;
  font-size: 12px;
  min-height: 16px;
}
