.basisPriceType {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.basisPriceTypeTitle {
  font-weight: bolder;
  margin-right: 16px;
}

.loading {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 80px;
  width: 100%;
}

.pagination {
  margin: 16px 0 0 auto;
  display: flex;
  justify-content: right;
}
