.cell {
  min-width: 150px;
}

.root {
  transform: translateY(-2px);
  vertical-align: unset !important;
  width: 100%;
  max-width: 400px;
}

.cell-small {
  width: 10%;
}

.cell-medium {
  width: 15%;
}
