.additionalCount {
  padding-left: 21px;
}

.borderTop {
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  height: 4px;
  width: 100%;
}

.borderTop.success {
  background-color: #00bc96;
}

.borderTop.failure {
  background-color: #cb6671;
}

.column {
  display: flex;
  flex-direction: column;
  min-width: 175px;
}

.icon {
  margin-right: 6px;
  position: relative;
  top: 3px;
}

.key {
  color: #00214a;
  font-size: 12px;
  font-weight: 500;
}

.marginTop {
  margin-top: 16px;
}

.paperContent {
  padding: 24px 24px 0;
  width: 100%;
}

.paperMargin {
  margin-bottom: 24px;
}

.row {
  display: flex;
  flex-direction: row;
}

.smallColumn {
  min-width: 100px;
}

.value {
  color: #4f5f82;
  font-size: 12px;
  margin-top: 8px;
  width: 100%;
}

.value:not(:last-of-type) {
  margin-bottom: 8px;
}
