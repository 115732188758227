.root {
  color: #011f42;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-bottom: 4px;
  width: 100%;
  min-width: 184px;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.key {
  color: #011f42;
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
}

.value {
  color: #4f5f82;
  font-size: 12px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
