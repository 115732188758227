.child {
  margin: 32px 0 0 32px;
}

.field {
  display: flex;
  flex-direction: column;
  width: 306px;
}

.key {
  color: #00214a;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
}

.orTag {
  color: #00214a;
  font-size: 12px;
  font-weight: 400;
  margin: 12px 16px;
}

.row {
  display: flex;
  flex-direction: row;
}
