.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 40px;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
}

.toolbar {
  justify-content: space-between;
  flex-flow: wrap;
  margin-bottom: 16px;
}

.pagination {
  margin: 16px 0 0 auto;
}

.errorCode {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
}
