.main-container {
  width: 100%;
  height: 90vh;
}

.nodes-health-container {
  width: 100%;
  max-height: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  column-gap: 25px;
  row-gap: 25px;
}

@media only screen and (max-width: 1366px) {
  .nodes-health-container {
    width: 100%;
    max-height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 503px);
    grid-template-rows: 1fr 1fr;
    column-gap: 25px;
    row-gap: 25px;
  }
}
