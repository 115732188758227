.root {
  display: flex;
  flex-direction: column;
}

.editSection {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgb(124 143 151 / 20%);
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  padding: 0 32px 32px 0;
  width: 100%;
}

.field {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0 32px;
  width: 216px;
}

.key {
  color: #00214a;
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 6px;
}

.marginBottom {
  margin-bottom: 54px;
}

.row {
  display: flex;
  flex-flow: row wrap;
}

.title {
  color: #011f42;
  font-size: 16px;
  margin: 26px 0 0 30px;
}
