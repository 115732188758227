.root {
  background: #fff;
  border-radius: 24px;
  box-shadow: 0 0 16px 2px rgb(1 126 250 / 10%),
    0 2px 4px -2px rgb(1 126 250 / 10%);
  height: 196px;
  margin-bottom: 24px;
  padding: 32px;
  width: 100%;
}

.column {
  display: flex;
  flex-direction: column;
}

.field {
  color: #0b1641;
  font-size: 14px;
  width: 48%;
}

.row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  color: #0b1641;
  font-size: 24px;
  font-weight: bolder;
  margin-bottom: 24px;
}
