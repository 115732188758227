.appBarSettings {
  align-items: center;
  display: flex;
  margin: 0 16px 0 auto;
}

.authUserName {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.column {
  display: flex;
  flex-direction: column;
  min-width: 0;
}

.row {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.icon {
  flex-basis: 20%;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user {
  min-width: 0;
  flex-basis: 70%;
}

.point {
  flex-basis: 10%;
}

.align-end-right {
  margin-left: auto;
}

.disabled {
  cursor: default;
}

.header {
  color: #002045;
  font-family: Graphik;
  font-size: 12px;
  font-weight: bolder;
  letter-spacing: 0.5px;
  margin: 12px 0 8px 18px;
}

.hide {
  display: none !important;
}

.link {
  text-decoration: none;
}

.logo {
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 56px;
}

.menuButton {
  margin-right: 8px !important;
  padding: 0 !important;
}

.menuButton:hover {
  background-color: transparent !important;
}

.scroll {
  overflow: hidden;
  padding: 8px 0 32px;
}

.scroll:hover {
  overflow-y: auto;
}

.tradingPartner {
  color: #fff;
  font-family: Graphik;
  font-size: 10px;
  margin-top: 4px;
}

.tradingPartnerGPO {
  margin: 16px 0 0 16px;
}

.xpType {
  bottom: 2px;
  color: #525f7f;
  font-family: Graphik;
  font-size: 10px;
  left: 54px;
  margin: 0;
  position: absolute;
}

.dashboard-menu {
  margin-top: 12px;
}
