.arrow {
  position: absolute;
  right: 6px;
}

.disabled {
  opacity: 0.5;
}

.icon {
  margin: 2px 3px 0 0;
}

.row {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.text,
.disabled {
  color: #525f7f;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
}

.error {
  color: #f44336;
  font-size: 0.75rem;
  height: 19px;
  letter-spacing: 0.0333em;
  position: absolute;
  top: 40px;
}
