@value colors: 'styles/base/colors.css';
@value Navy from colors;

/* Headers */
.HeaderH1 {
  color: Navy;
  font-size: 40px;
  letter-spacing: -0.02em;
  line-height: 48px;
}

.HeaderH1Medium {
  font-weight: 500;
  composes: HeaderH1;
}

.HeaderH1SemiBold {
  font-weight: 600;
  composes: HeaderH1;
}

.HeaderH1Bold {
  font-weight: 700;
  composes: HeaderH1;
}

.HeaderH1ExtraBold {
  font-weight: 800;
  composes: HeaderH1;
}

.HeaderH2 {
  color: Navy;
  font-size: 36px;
  letter-spacing: -0.02em;
  line-height: 44px;
}

.HeaderH2Medium {
  font-weight: 500;
  composes: HeaderH2;
}

.HeaderH2SemiBold {
  font-weight: 600;
  composes: HeaderH2;
}

.HeaderH2Bold {
  font-weight: 700;
  composes: HeaderH2;
}

.HeaderH2ExtraBold {
  font-weight: 800;
  composes: HeaderH2;
}

.HeaderH3 {
  color: Navy;
  font-size: 32px;
  letter-spacing: -0.02em;
  line-height: 40px;
}

.HeaderH3Medium {
  font-weight: 500;
  composes: HeaderH3;
}

.HeaderH3SemiBold {
  font-weight: 600;
  composes: HeaderH3;
}

.HeaderH3Bold {
  font-weight: 700;
  composes: HeaderH3;
}

.HeaderH3ExtraBold {
  font-weight: 800;
  composes: HeaderH3;
}

.HeaderH4 {
  color: Navy;
  font-size: 28px;
  letter-spacing: -0.02em;
  line-height: 36px;
}

.HeaderH4Medium {
  font-weight: 500;
  composes: HeaderH4;
}

.HeaderH4SemiBold {
  font-weight: 600;
  composes: HeaderH4;
}

.HeaderH4Bold {
  font-weight: 700;
  composes: HeaderH4;
}

.HeaderH4ExtraBold {
  font-weight: 800;
  composes: HeaderH4;
}

.HeaderH5 {
  color: Navy;
  font-size: 24px;
  letter-spacing: -0.02em;
  line-height: 32px;
}

.HeaderH5Medium {
  font-weight: 500;
  composes: HeaderH5;
}

.HeaderH5SemiBold {
  font-weight: 600;
  composes: HeaderH5;
}

.HeaderH5Bold {
  font-weight: 700;
  composes: HeaderH5;
}

.HeaderH5ExtraBold {
  font-weight: 800;
  composes: HeaderH5;
}

.HeaderH6 {
  color: Navy;
  font-size: 20px;
  letter-spacing: -0.02em;
  line-height: 28px;
}

.HeaderH6Medium {
  font-weight: 500;
  composes: HeaderH6;
}

.HeaderH6SemiBold {
  font-weight: 600;
  composes: HeaderH6;
}

.HeaderH6Bold {
  font-weight: 700;
  composes: HeaderH6;
}

.HeaderH6ExtraBold {
  font-weight: 800;
  composes: HeaderH6;
}

/* Paragraphs */
.ParagraphLarge {
  color: Navy;
  font-size: 18px;
  line-height: 28px;
}

.ParagraphLargeRegular {
  font-weight: 400;
  composes: ParagraphLarge;
}

.ParagraphLargeMedium {
  font-weight: 500;
  composes: ParagraphLarge;
}

.ParagraphLargeSemiBold {
  font-weight: 600;
  composes: ParagraphLarge;
}

.ParagraphLargeUnderline {
  text-decoration: underline;
  composes: ParagraphLargeRegular;
}

.ParagraphLargeStrikethrough {
  text-decoration: line-through;
  composes: ParagraphLargeRegular;
}

.ParagraphLargeItalic {
  font-style: italic;
  composes: ParagraphLargeRegular;
}

.ParagraphMedium {
  color: Navy;
  font-size: 16px;
  line-height: 24px;
}

.ParagraphMediumRegular {
  font-weight: 400;
  composes: ParagraphMedium;
}

.ParagraphMediumMedium {
  font-weight: 500;
  composes: ParagraphMedium;
}

.ParagraphMediumSemiBold {
  font-weight: 600;
  composes: ParagraphMedium;
}

.ParagraphMediumUnderline {
  text-decoration: underline;
  composes: ParagraphMediumRegular;
}

.ParagraphMediumStrikethrough {
  text-decoration: line-through;
  composes: ParagraphMediumRegular;
}

.ParagraphMediumItalic {
  font-style: italic;
  composes: ParagraphMediumRegular;
}

.ParagraphSmall {
  color: Navy;
  font-size: 14px;
  line-height: 20px;
}

.ParagraphSmallRegular {
  font-weight: 400;
  composes: ParagraphSmall;
}

.ParagraphSmallMedium {
  font-weight: 500;
  composes: ParagraphSmall;
}

.ParagraphSmallSemiBold {
  font-weight: 600;
  composes: ParagraphSmall;
}

.ParagraphSmallUnderline {
  text-decoration: underline;
  composes: ParagraphSmallRegular;
}

.ParagraphSmallStrikethrough {
  text-decoration: line-through;
  composes: ParagraphSmallRegular;
}

.ParagraphSmallItalic {
  font-style: italic;
  composes: ParagraphSmallRegular;
}

.ParagraphXSmall {
  color: Navy;
  font-size: 12px;
  line-height: 20px;
}

.ParagraphXSmallRegular {
  font-weight: 400;
  composes: ParagraphXSmall;
}

.ParagraphXSmallMedium {
  font-weight: 500;
  composes: ParagraphXSmall;
}

.ParagraphXSmallSemiBold {
  font-weight: 600;
  composes: ParagraphXSmall;
}

.ParagraphXSmallUnderline {
  text-decoration: underline;
  composes: ParagraphXSmallRegular;
}

.ParagraphXSmallStrikethrough {
  text-decoration: line-through;
  composes: ParagraphXSmallRegular;
}

.ParagraphXSmallItalic {
  font-style: italic;
  composes: ParagraphXSmallRegular;
}
