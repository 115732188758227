.content {
  display: flex;
  flex-wrap: wrap;
}

.icon {
  margin-left: 8px;
  position: relative;
  top: 2px;
}
