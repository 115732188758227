.errorCode {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
}

.search {
  margin: 1rem 0;
}

.pagination {
  margin-top: 1rem;
  float: right;
}
