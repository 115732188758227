.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

form {
  margin-bottom: 0;
}

.errorCode {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
}

.head {
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
}

.icon {
  position: relative;
  top: 4px;
}

.icon:not(:last-of-type) {
  margin-right: 4px;
}

.link {
  color: #1482fc;
  text-decoration: none;
}

.loading {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 80px;
  width: 100%;
}

.pagination {
  margin: 16px 0 0 auto;
}

.paper {
  max-height: 100%;
  overflow-y: auto;
}

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.floatRight {
  float: right;
}

.row {
  display: flex;
  flex-direction: row;
}

.toolbar {
  justify-content: end;
  flex-flow: wrap;
  margin-bottom: 16px;
}

.CreateEditCustomerList_row__robJv {
  padding-bottom: 20px;
}
