.root {
  display: flex;
  flex-direction: row;
}

.buttons {
  align-self: flex-end;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: hidden;
}

.documents-column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  height: 100%;
  position: relative;
}

.overview {
  display: inline-block;
  width: 100%;
}
