.errorCode {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
}

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 40px;
}

.pagination {
  margin: 16px 0 0 auto;
}

.toolbar {
  display: flex;
  margin-bottom: 16px;
  position: relative;
}
