.arrow {
  cursor: pointer;
  padding-right: 0 !important;
}

.expanded div {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
