.ellipsis {
  display: inline-block;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.row {
  display: flex;
  flex-direction: row;
}

.value {
  color: #4f5f82;
  display: flex;
  font-size: 12px;
  max-width: 88%;
}
