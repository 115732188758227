.root {
  color: #011f42;
  display: flex;
  flex-direction: column;
  font-family: Graphik;
  font-size: 16px;
  margin-bottom: 4px;
  width: 100%;
  min-width: 184px;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.column:not(:first-of-type) {
  margin-left: 24px;
}

.key {
  color: #011f42;
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
  white-space: nowrap;
}

.marginTop {
  margin-top: 8px;
}

.row {
  display: flex;
  flex-direction: row;
}

.value {
  color: #4f5f82;
  font-size: 12px;
  white-space: nowrap;
}
