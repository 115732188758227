.root {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 80px;
  width: 100%;
}

.text {
  color: #5e6977;
  font-family: Graphik;
  font-size: 18px;
  font-weight: 200;
  margin-top: 24px;
}
