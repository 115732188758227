.handle {
  background: #017efa;
  border-color: #017efa;
  height: 16px;
  outline: 3px solid white;
  outline-offset: -5px;
  right: -6px;
  width: 16px;
}

.name {
  padding: 9px 41px;
  width: 116px;
  height: 40px;
  border-radius: 80px;
  text-align: center;
}
