.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding-top: 40px;
  width: 100%;
}

.pagination {
  margin: 16px 0 0 auto;
}

.search {
  width: 470px;
  float: left;
}

.toolbar {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}
