.toolbar-container {
  justify-content: space-between;
}

.title {
  width: 80%;
}

.close-button {
  float: right;
}
