.column {
  display: flex;
  flex-direction: column;
  min-width: fit-content;
}

.defaultHandle {
  background: #6342ff;
  border-color: #6342ff;
  height: 16px;
  outline: 3px solid white;
  outline-offset: -5px;
  right: -6px;
  width: 16px;
}

.hiddenHandle {
  width: 16px;
  height: 16px;
  right: -6px;
  opacity: 0;
}

.errors {
  border: 2px solid #ef4444;
}

.errorsMessage {
  color: #ef4444;
  position: absolute;
  bottom: -23px;
  font-weight: 400;
  font-size: 14px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.icon {
  background-color: #fff;
  cursor: pointer;
  height: 36px;
  margin-left: 32px;
  position: relative;
  top: -20px;
  width: 36px;
}

.name {
  color: #0b1641;
  font-size: 18px;
  font-style: bold;
  font-weight: 600;
}

.node {
  align-items: flex-start;
  background: #fff;
  border-radius: 24px;
  box-shadow: 0 0 16px 2px rgb(1 126 250 / 10%),
    0 2px 4px -2px rgb(1 126 250 / 10%);
  height: 128px;
  padding: 32px;
}

.row {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.title {
  color: #0b1641;
  cursor: pointer;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  margin-bottom: 8px;
}
