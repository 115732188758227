.active {
  background-color: #00bc96;
}

.button {
  background-color: #fff;
  border: none;
  color: #525f7f;
  cursor: pointer;
  font-size: 12px;
  outline: none;
  width: 100%;
}

.center {
  align-items: center;
}

.circle {
  border: solid 1.5px #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgb(82 95 127 / 15%);
  display: inline-block;
  height: 16px;
  margin-bottom: 4px;
  width: 16px;
}

.column {
  display: flex;
  flex-direction: column;
}

.date {
  width: max-content;
}

.disabled {
  cursor: default !important;
}

.document {
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgb(124 143 151 / 11%);
  color: #525f7f;
  height: 80px;
  font-size: 12px;
  justify-content: space-between;
  margin-bottom: 16px;
  padding: 18px 24px;
  position: relative;
  width: 100%;
}

.failed {
  background-color: #cb6671;
}

.leftText {
  margin-left: 22px;
  text-align: left;
}

.pagination {
  margin-left: auto;
}

.pending {
  background-color: #f9bc60;
}

.removeIcon {
  cursor: pointer;
  margin: 0 0 6px 16px;
}

.row {
  align-items: center;
  display: flex;
  flex-direction: row;
}

.title {
  color: #011f42;
  font-weight: bold;
  margin-bottom: 4px;
}

.toolbar {
  float: right;
  margin-bottom: 16px;
}
