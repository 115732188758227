.root {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgb(124 143 151 / 11.3%);
  margin-bottom: 24px;
  padding: 24px;
}

.field {
  display: flex;
  flex-direction: column;
  width: 216px;
}

.header {
  color: #011f42;
  font-size: 16px;
  margin-bottom: 24px;
}

.key {
  color: #00214a;
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
}

.child-container {
  padding: 10px 38px;
}
