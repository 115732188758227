.buttons {
  display: flex;
  height: 40px;
  justify-content: flex-end;
  margin-bottom: -40px;
  margin-left: auto;
  position: relative;
  width: fit-content;
  z-index: 1;
}

.buttons-plus {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 16px;
  margin-left: auto;
  position: relative;
  width: fit-content;
  z-index: 1;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.errorCode {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 1360px) {
  .buttons {
    margin-bottom: 0;
  }
}
