.root {
  font-family: Graphik;
  font-weight: normal;
  font-size: 12px;
  line-height: 24px;
  width: 100%;
  padding: 20px;
}

button.disabled {
  color: #525f7f;
}

button.default {
  color: #525f7f;
  stroke: #525f7f;
}

button.default:hover {
  color: #394157;
  stroke: #394157;
}

button.activated {
  background: #eef6ff;
  border: 2px solid #1482fc;
  color: #1482fc;
  stroke: #1482fc;
}

button.activated:hover {
  background: #e4eefa;
  border: 2px solid #0561c8;
  color: #0561c8;
  stroke: #0561c8;
}

button.open {
  border-radius: 5px 5px 0 0;
}

.popover {
  border: 1px solid #e4ebef;
  border-radius: 0 5px 5px;
}
