.buttonContainer {
  float: right;
  margin: 0 24px 24px;
}

.text {
  color: #525f7f;
  font-family: Graphik, sans-serif;
  font-size: 12px;
  margin: 0 0 44px 24px;
}

.form {
  padding: 0 25px 25px;
}
