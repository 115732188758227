@import 'react-querybuilder/dist/query-builder.css';

.rule-remove,
.ruleGroup-remove,
.rule-cloneRule,
.ruleGroup-cloneGroup {
  background-color: #fff;
  border: 1px solid #e4ebef;
  color: #525f7f;
  height: 40px;
  min-width: 40px;
}

.rule {
  margin-bottom: 16px;
}

.ruleGroup {
  background: #fff;
  border: none;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgb(124 143 151 / 20%);
  padding: 24px;
}

.ruleGroup-addGroup,
.ruleGroup-addRule {
  background: #1482fc;
  border: solid 1px #e4ebef;
  border-radius: 4px;
  color: #fff;
  font-family: Graphik;
  font-size: 12px;
  height: 40px;
  min-width: 80px;
}

.ruleGroup-notToggle {
  cursor: pointer;
  display: flex;
  margin: 0 16px;
}

.ruleGroup-notToggle > input {
  height: 20px;
  margin-right: 8px;
  min-width: fit-content;
}

.queryBuilder input,
select {
  font-family: Graphik;
}

.queryBuilder:not(:last-child) {
  margin-bottom: 32px;
}

.queryBuilder-branches .rule::after {
  height: 90%;
}
