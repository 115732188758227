.root {
  display: flex;
  flex-direction: row;
  height: 100%;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  height: 100%;
  position: relative;
}
