.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.errorCode {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
}

.row {
  display: flex;
  flex-direction: row;
}

.toolbar {
  justify-content: end;
  flex-flow: wrap;
  margin-bottom: 16px;
}
