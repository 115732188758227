.iconAlert {
  margin-left: 4px;
}

.tooltipRoot {
  color: #011f42;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  margin-bottom: 4px;
  width: 100%;
  min-width: 184px;
}

.value {
  color: #4f5f82;
  display: flex;
  font-size: 12px;
  margin-top: 16px;
  max-width: 88%;
}
