.error-alert-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.error-alert-title {
  color: #b91c1c;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.error-alert-description {
  color: #b91c1c;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.error-alert-action {
  cursor: pointer;
  margin-top: 8px;
  color: #b91c1c;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
}

.success-alert-title {
  color: #15803d;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}
