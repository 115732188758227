.root {
  align-items: center;
  background-color: #011f42;
  border-radius: 8px;
  box-shadow: 0 2px 6px 0 rgb(124 143 151 / 11%);
  color: #fff;
  display: flex;
  font-size: 16px;
  height: 56px;
  padding-left: 24px;
}

.paperMargin {
  margin-bottom: 24px;
}
