.root {
  background: #fff;
  border-radius: 24px;
  box-shadow: 0 0 16px 2px rgb(1 126 250 / 10%),
    0 2px 4px -2px rgb(1 126 250 / 10%);
  height: 562px;
  overflow: hidden;
  padding: 32px;
  min-width: 324px;
}

.draggableNode {
  align-items: center;
  background: #fff;
  border: 1px solid #e8e7eb;
  border-radius: 24px;
  box-shadow: 0 0 16px 2px rgb(1 126 250 / 10%),
    0 2px 4px -2px rgb(1 126 250 / 10%);
  cursor: pointer;
  display: flex;
  font-size: 18px;
  font-weight: 600;
  height: 92px;
  padding: 32px;
}

.draggableNode:not(:last-child) {
  margin-bottom: 20px;
}

.draggableRoot {
  background: #fff;
  margin-top: 16px;
  max-height: 90%;
  overflow-y: auto;
  overflow-x: hidden;
}

.title {
  color: #0b1641;
  font-size: 24px;
  font-weight: bolder;
}
