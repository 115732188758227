.container {
  display: flex;
  flex-direction: column;
}

.buttons {
  margin-left: auto;
}

.toolbar {
  justify-content: space-between;
  flex-flow: wrap;
}

.navigation-bar {
  margin: 20px 0 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.progress-bar {
  flex-grow: 2;
  overflow-x: auto;
  max-width: 470px;
  width: 100%;
  display: flex;
}
