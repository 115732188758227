.arrowContainer {
  align-items: center;
  border: 1px solid #e4ebef;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  width: 24px;
  margin-left: 12px;
}

.header {
  color: #011f42;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin: 24px 0;
  display: flex;
}

.row {
  width: 100%;
}
