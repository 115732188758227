.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  text-align: center;
  width: 100%;
}

.bottom {
  background-color: #f7f8fa;
  flex-basis: 50%;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
}

.firstBlock {
  margin-top: 24px;
}

.form {
  background-color: #fff;
  border-radius: 4.8px;
  box-shadow: 0 2px 5px 0 rgb(124 143 151 / 11%);
  padding: 32px;
  flex-basis: 200px;
  width: 512px;
  transform: scale(0);
  transition: transform 0.2s ease-in-out;
}

.form.ready {
  transform: scale(1);
}

form {
  margin-bottom: 32px;
}

form:last-child {
  margin-bottom: 0;
}

.inputBlock {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 20px 0;
}

.link {
  color: #0084ff;
  font-family: Graphik;
  font-size: 12px;
  line-height: 1.5;
  margin-top: 20px;
}

.loginError {
  color: #da5f6f;
  font-family: Graphik;
  font-size: 12px;
  line-height: 1.33;
  margin: 1em 0;
  text-align: center;
  visibility: hidden;
  width: 100%;
}

.pill {
  margin-left: auto;
  overflow: hidden;
}

.show {
  visibility: visible;
}

.subHeader {
  color: #00214a;
  font-family: Graphik;
  font-size: 12px;
  font-weight: bold;
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 4px;
}

.subtext {
  color: #3d628b;
  font-family: Graphik;
  font-size: 12px;
  line-height: 1.5;
  margin: 0;
}

.syringe {
  align-self: flex-end;
  margin-right: auto;
}

.title {
  color: #011f42;
  font-family: Graphik;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.71;
  margin: 0;
}

.top {
  background-color: #011f42;
  display: flex;
  flex-basis: 50%;
}
