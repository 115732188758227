.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.errorCode {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
}

.column {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.row {
  display: flex;
  width: 100%;
}

.marginBottom {
  margin-bottom: 16px;
}

.content {
  display: flex;
  flex-wrap: wrap;
}

.header {
  color: #011f42;
  font-size: 16px;
  margin-bottom: 24px;
}

.key {
  color: #00214a;
  font-size: 12px;
  font-weight: 500;
  margin-top: 8px;
}

.paperContent {
  padding: 24px 24px 0;
  width: 100%;
}

.paperMargin {
  margin-bottom: 24px;
}

.divider {
  width: 100%;
  border: none;
  border-top: 1px solid #e4ebef;
}

.toolbar {
  justify-content: space-between;
  flex-flow: wrap;
  margin-bottom: 16px;
}

.pagination {
  margin: 16px 0 0 auto;
}

.loading {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 80px;
  width: 100%;
}
