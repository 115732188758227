.card {
  border-radius: 5px;
  box-shadow: 0 2px 6px 0 rgb(203 102 113 / 34%);
  margin-right: 16px;
  padding: 24px;
  width: 250px;
  max-height: 250px;
}

.cardField {
  width: 100% !important;
}

.column {
  display: flex;
  flex-direction: column;
}

.container {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 6px 0 rgb(124 143 151 / 11%);
  padding: 24px;
  width: 100%;
}

.ellipsis {
  display: inline-block !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;
}

.header {
  color: #011f42;
  font-size: 20px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 24px;
  display: flex;
}

.green {
  background-color: #00bc96;
}

.greenOpacity {
  background-color: rgb(0 188 150 / 40%);
  fill: #00bc96;
}

.red {
  background-color: #cb6671;
}

.redOpacity {
  background-color: rgb(203 102 113 / 40%);
  fill: #cb6671;
}

.yellow {
  background-color: #f9bc60;
}

.yellowOpacity {
  background-color: rgb(249 188 96 / 40%);
  fill: #f9bc60;
}

.blue {
  background-color: #00bcd4;
}

.blueOpacity {
  background-color: rgb(0 188 212 / 40%);
  fill: #00bcd4;
}

.icon {
  margin-left: 8px;
}

.iconBackground {
  align-items: center;
  border-radius: 4px;
  display: flex;
  height: 32px;
  justify-content: center;
  margin-right: 16px;
  width: 32px;
}

.key {
  color: #00214a;
  font-size: 14px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 8px;
  font-family: Graphik, sans-serif;
  font-style: normal;
}

.leftMargin {
  margin-left: 48px;
}

.topMargin {
  margin-top: 24px;
}

.letterSpacing {
  letter-spacing: 1px;
}

.link {
  color: #1482fc;
  text-decoration: none;
}

.whiteLink {
  color: #fff;
}

.longField {
  width: 100% !important;
}

.mediumField {
  width: 66% !important;
}

.propertyGroup {
  margin-bottom: 24px;
  width: 33%;
}

.row {
  display: flex;
  flex-direction: row;
}

.divider {
  border-top: 1px solid #e4ebef;
}

.spaceBetween {
  justify-content: space-between;
}

.tooltip {
  color: #4f5f82;
  font-family: Graphik, sans-serif;
  font-size: 12px;
}

.tooltipHeader {
  color: #011f42;
  display: flex;
  font-size: 16px;
  margin-bottom: 8px;
}

.white {
  color: #fff !important;
}

.value {
  color: #4f5f82;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 80%;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
}

.paragraph {
  margin: 0 32px 24px 0;
}
